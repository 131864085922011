<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="10"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="10"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal member">
                    <div class="fs20 col10 fwb pb30">{{$t('select')}}</div>
                    <div class="flex-box pb40">
                        <a href="javascript:;" class="btn select mr30" @click="select">{{$t('select')}}</a>
                    </div>
                    
                    <!-- <div class="fs20 col10 fwb pb25">{{$t('select')}}</div>
                    <div class="flex-box flex-wrap flex-col-start">
                        <div class="input_item">

                            <el-input :readonly="true" class="input mb30" placeholder="Enter Contact Person Number"
                                v-model="inviteid"></el-input>
                        </div>
                        
                        <div class="input_item">
                            <el-input :readonly="true" class="input mb30" placeholder="Enter Contact Person Number"
                                v-model="choose"></el-input>
                        </div>
                        
                        <div class="input_item">
                            <el-select v-model="market" class="input mb30" placeholder="Choose Market">
                                <el-option v-for="(item, index) in options" :key="index" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input_item">
                            <a href="javascript:;" class="btn select mr40 make" @click="make">生成注册码</a>

                        </div>
                    </div> -->
                    <div class="fs20 col10 fwb pb25">{{$t('invitecode')}}</div>
                    <div class="flex-box mb30 amount_box">
                        <el-input class="input amount" :readonly="true" placeholder="Please enter the card printing amount" v-model="code"></el-input>
                        <a href="javascript:;" class="btn select mr40 make" @click="copy">{{$t('copy')}}</a>
                    </div>
                    
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { numberToCurrency } from "../assets/filters";
import cookie from 'vue-cookies';
import CryptoJS from 'crypto-js'

export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            inviteid:'',
            options: [{
                value: '1',
                label: '左区'
            }, {
                value: '2',
                label: '右区'
            },],
            market: '',
            item: '',
            email: '',
            idnum: '',
            password: '',
            tpassword: '',
            price: '',
            ismake: false,
            articledetail: '',
            choose: '',
            code: '',
            type:4,
        };
    },
    created() {
        if (cookie.get('select2')) {
            var select = cookie.get('select2')
            console.log(select)
            this.item = select.item
            this.choose = '【' + this.item.id + '】' + this.item.idnum
            console.log(this.item)
            this.market = select.index.toString()
           
        }
        if(this.$route.query.choosetype){
            this.type=this.$route.query.choosetype
        }
        this.config()
        this.getuser()

    },
    methods: {
        // 加密方法
		encrypt(str, key) {
			return CryptoJS.AES.encrypt(str, key).toString()
		},
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){

					this.inviteid = ret.data.userinfo.id;
                    if (cookie.get('select2')) {

                        var code = this.inviteid+'-'+this.item.id+'-0'+this.market
                        this.code=this.encrypt(code, "123sd")
                        cookie.remove('select2')
                    }

				}
			})
        },
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail = res.data.member
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        gohistory() {
            this.$router.push({ path: '/history' })
        },
        select() {
            this.$router.push({ path: '/map', query: { type: 0,choosetype:this.type ,pagetype:1} })
        },
        copy(){
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value =this.code;
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            // this.$message.success('success!')
            this.$message({
                message: "复制成功",
                type: 'success',
                offset: window.screen.height / 2
            })
            //移除input标签
            document.body.removeChild(input)
        },
        make() {
            // if (!this.ismake) {
                if (!this.item) {
                    return this.$message({
                        message: '请选择',
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
                console.log(this.type) 
                // this.ismake = true
                this.code = this.inviteid+'-'+this.item.id+'-0'+this.market
                console.log(code)
              
            // }
        }
    }
};
</script>
